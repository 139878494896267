
body{
    
}

.BgTop{
    background-image: url('/src/assets/images/asian-elderly.png') no-repeat ;
    height: 550px;
}
.about{
    margin-left: 300px;
    margin-top: 239px;
    max-width: 782px;
 }