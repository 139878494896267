
@import url('https://cdn.rawgit.com/mfd/f3d96ec7f0e8f034cc22ea73b3797b59/raw/856f1dbb8d807aabceb80b6d4f94b464df461b3e/gotham.c');
@import url(//db.onlinewebfonts.com/c/3a47f5f06b5484abfeee9eac90348a9c?family=Gotham);
body {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
}
Nav a {
    color: #502773;
}
.logo{
    padding: 40px 30px 30px 5px;

  }
.color a{
    color: #502773 ;
}
.active{
    color: #502773 ;
}
.active:active{
    color: #502773;
}
.menu{
    
    font-family: "TruenoRegular", Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    margin-top: 6px;
    /* identical to box height, or 111% */
    
}
.menu:hover {
    color: #fff;
}

 .nav-link:hover {
    background: #502773;
    color: #fff !important;
}

.nav-link h5:hover{
   
}

.logo-container {
    padding: 60px;
}

@media (max-width: 992px) {
    .nav-link {
  
      padding: 10px;}
   
    .navbar-collapse.collapsing .navbar-nav {
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        left: -45%;
        transition: all 0.2s ease;
    }

    .navbar-collapse.show .navbar-nav {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        flex-direction: column;
        height: auto;
        width: 45%;
        transition: left 0.35s ease;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        background: #fff;
        padding: 15px;
    }

    .logo-container {
        padding: 0;
    }
}

.nav-container {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}