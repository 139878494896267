/* @import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css"); */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css");
/* @import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"); */

/* ==========================================================================
   Responsive images and box padding
   ========================================================================== */
   /* @font-face {
    font-family: 'Gotham';
    src: local('Gotham'), url(./assets/fonts/GothamMedium.ttf) format('truetype');
  }
  @font-face {
    font-family: 'NunitoSans';
    src: local('NunitoSans'), url(./assets/fonts/NunitoSans-Regular.ttf) format('truetype');
  } */
img,
object {
  display: block;
  border: 0;
  max-width: 100%;
  height: auto;
}
.select img,
object {
  display: block;
  border: 0;
  max-width: 100%;
  height: auto;
}
* {
  -moz-box-sizing: border-box;
  -webkit-kit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

body {
  /* font-family: "TruenoUltralight", Arial, sans-serif; */
  /* font-size: 100%; */
  background: #fefefe;
}

/* b, strong {
  font-family: "TruenoBold", Arial, sans-serif;
  font-weight: bolder;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "TruenoRegular", Arial, sans-serif;
} */

.container {
  /* width: 80%; */
  margin: 60px auto;
}

/* Hide arrow input type number - start */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Hide arrow input type number - end */