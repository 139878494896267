/* Gaya dasar untuk .search-container */
.search-container {
  display: flex;
  gap: 17px;
  align-items: center;
  width: 100%;
}

/* Gaya untuk tombol */
.search-container button {
  width: 180px;
  height: 50px;
  color: white;
  font-family: "TruenoBold", Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  background: #502773;
  border-radius: 5px;
  border: none;
  text-align: center;
  overflow: visible;
}

/* Media query untuk tampilan mobile */
@media (max-width: 768px) {
  .search-container {
    flex-direction: column;
    gap: 10px; 
    align-items: stretch;
  }

  .search-container .input {
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
  }

  .search-container .input::placeholder {
    font-size: 12px;
  }

  .search-container button {
    width: 100%;
    max-width: 400px;
    font-size: 14px;
    height: 40px;
    border-radius: 20px;
  }
}


.btn-back{
  width: 70px;
  height: 26px;
  color: white;
  font-family: "TruenoBold", Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 28px;
  background: #502773;
  border-radius: 13px;
  border: none;
  text-align: center;
}

.btn-showRoute{
  width: 100px;
  height: 30px;
  color: white;
  font-family: "TruenoBold", Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 28px;
  background: #502773;
  border-radius: 15px;
  border: none;
  text-align: center;

}
